import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101')
];

export const server_loads = [0];

export const dictionary = {
		"/": [2],
		"/admin": [3],
		"/admin/brandsorting": [4],
		"/admin/createaudiencev2": [6],
		"/admin/createaudience": [5],
		"/admin/editaudiences": [7],
		"/admin/editfiltergroups": [8],
		"/admin/manualfigure": [9],
		"/admin/status": [10],
		"/admin/usercontrols": [11],
		"/ads": [12],
		"/ads/[slug]": [13],
		"/artprints": [14],
		"/artprints/[filter]": [15],
		"/artprints/[filter]/[property]": [16],
		"/automation/apple/archives": [17],
		"/automation/apple/backups": [18],
		"/automation/apple/recommendations": [19],
		"/cancelledbreakout": [23],
		"/cancelledbreakout/[filter]": [24],
		"/cancelledbreakout/[filter]/[property]": [25],
		"/cancelled": [20],
		"/cancelled/[filter]": [21],
		"/cancelled/[filter]/[property]": [22],
		"/filteredview": [26],
		"/filteredview/orders": [29],
		"/filteredview/orders/[filter]": [30],
		"/filteredview/orders/[filter]/[property]": [31],
		"/filteredview/revper": [32],
		"/filteredview/revper/[filter]": [33],
		"/filteredview/revper/[filter]/[property]": [34],
		"/filteredview/[filter]": [27],
		"/filteredview/[filter]/[property]": [28],
		"/geo/country": [38],
		"/geo/country/[code]": [39],
		"/geo/[filter_country]": [35],
		"/geo/[filter_country]/[property]": [36],
		"/geo/[filter_country]/[property]/[code]": [37],
		"/language/locale": [43],
		"/language/locale/[code]": [44],
		"/language/[filter_locale]": [40],
		"/language/[filter_locale]/[property]": [41],
		"/language/[filter_locale]/[property]/[code]": [42],
		"/monitoring/commissionjunctionfulfillment": [45],
		"/monitoring/commissionjunctionorders": [46],
		"/monitoring/conversionstatus": [49],
		"/monitoring/conversions": [47],
		"/monitoring/conversions/[slug]": [48],
		"/monitoring/deployments": [50],
		"/monitoring/manualfigures": [51],
		"/monitoring/storedalarms": [52],
		"/monitoring/storedalarms/[property]": [53],
		"/notifications/create": [54],
		"/notifications/review": [55],
		"/notifications/review/[notification]": [56],
		"/orders": [57],
		"/orders/byplatform": [58],
		"/orders/bystore": [59],
		"/orders/commission": [60],
		"/orders/revper": [61],
		"/predictive": [62],
		"/predictive/new_purchaser": [63],
		"/predictive/new_purchaser/[filter]": [64],
		"/predictive/new_purchaser/[filter]/[property]": [65],
		"/predictive/weighted_average": [66],
		"/predictive/weighted_average/[model]/[start]/[end]": [67],
		"/product": [68],
		"/product/filtered": [70],
		"/product/filtered/[filter]": [71],
		"/product/filtered/[filter]/[property]": [72],
		"/product/filtered/[filter]/[property]/[product]": [73],
		"/product/[product]": [69],
		"/reports": [74],
		"/reports/amazon": [75],
		"/reports/builder": [76],
		"/reports/builder/[start]/[end]/[granularity]/[sorting]/[platform]/[dates]": [77],
		"/reports/exchange": [78],
		"/reports/fiscalmonths": [79],
		"/reports/topline": [80],
		"/reports/walgreensdecayrate": [81],
		"/reports/walgreenspickuprate": [82],
		"/reports/ytd": [83],
		"/sentry-example": [84],
		"/stores": [85],
		"/stores/filtered": [86],
		"/stores/filtered/[platform]": [87],
		"/support": [88],
		"/support/disputes": [89],
		"/support/ticketflow": [90],
		"/troas": [91],
		"/troas/[platform]": [92],
		"/troas/[platform]/miccam/[single]": [95],
		"/troas/[platform]/[campaigns]": [93],
		"/troas/[platform]/[campaigns]/[single]": [94],
		"/users": [96],
		"/users/averages": [97],
		"/users/averages/[filter]": [98],
		"/users/new_per_day": [99],
		"/users/new_per_day/[filter]": [100],
		"/users/new_per_day/[filter]/[property]": [101]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';